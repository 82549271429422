import React from 'react';
import { Link } from 'react-router-dom';
import '../css/ShopByCategory.css';
import {useMediaQuery} from "react-responsive";

const categories = [
    { id: 1, name: 'Customised Air Fresheners', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALL%C2%B7E+2024-07-25+08.51.49+-+A+creative+and+inviting+image+of+a+customizable+paper+hanging+air+freshener.+The+air+freshener+is+blank+and+white%2C+shaped+like+a+classic+tree+silhouet.webp', linkUrl: '/shapes' },
    { id: 2, name: 'Bike Parts', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-10_17.06.03_-_A_stylish_motorcycle_with_a_sleek_modern_design_parked_in_f_G159bUp.webp', linkUrl: '/storenew/215870942/摩托车' },
    { id: 3, name: 'Car Parts', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-10_17.08.19_-_A_sleek_electric_car_with_a_modern_design_parked_in_front_o_LeshfR9.webp', linkUrl: '/storenew/215870942/汽车配件' },
    { id: 10, name: 'Car electronics', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.43.30_-_A_realistic_image_designed_for_a_website_category_button_de_HQsxiow.webp', linkUrl: '/storenew/230140297/null' },
    { id: 11, name: 'Car Safety', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.34.56_-_A_realistic_image_designed_for_a_website_category_button_de_xCcYyiL.webp', linkUrl: '/storenew/230140299/null' },
    { id: 12, name: 'Car Interior', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.36.01_-_A_realistic_image_designed_for_a_website_category_button_de_6241KLM.webp', linkUrl: '/storenew/230140295/null' },
    { id: 13, name: 'Car Audio and Navigation', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.37.01_-_A_realistic_image_designed_for_a_website_category_button_de_smS3C3G.webp', linkUrl: '/storenew/230140294/null' },
    { id: 14, name: 'Car Cushion', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.37.50_-_A_realistic_image_designed_for_a_website_category_button_de_3o5hNEF.webp', linkUrl: '/storenew/230140296/null' },
    { id: 15, name: 'Electric Scooters', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.38.51_-_A_realistic_image_designed_for_a_website_category_button_de_ASgMGUf.webp', linkUrl: '/storenew/230140298/null' },
    { id: 5, name: 'Aerosol Products', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.45.42_-_A_realistic_image_designed_for_a_website_category_button_de_prm6GKn.webp', linkUrl: '/store/8' },
];

function ShopByCategory() {

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  return (
    <div className="shop-by-category">
      <h2>Shop by Category</h2>
      <div className="category-list">
        {categories.map((category, index) => (
          <Link key={category.id} to={category.linkUrl} className={["category-link", (isMobile ? (index === 0 || index === 3 || index === 8) : (index === 0 || index === 4 || index === 8)) ? "category-link-lg" : ""].join(" ")}>
            <div className="category">
              <img src={category.imageUrl} alt={category.name} />
                  <div className="category-overlay-img">
                   <h3 className="category-name">{category.name}</h3>
                 </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ShopByCategory;



